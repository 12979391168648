import React from "react";
import logo from "./logo.svg";
import "./App.css";
import UploadBlock from "./components/UploadBlock.component";
import { GetDealerLogosButton } from "./components/GetDealerLogosButton.component";

function App() {
  return (
    <div className="App">
      <header className="">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>

      <UploadBlock label="Upload a file" image_type={null} />

      <UploadBlock label="Upload a dealer logo" image_type="dealerLogo" />

      <GetDealerLogosButton />
    </div>
  );
}

export default App;
