import axios from "axios";
import { config } from "../config";
import { ImageFile } from "../models";

export namespace ImageApiService {
  // const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT ?? "NO_REACT_APP_API_ENDPOINT";
  const API_ENDPOINT =
    config.REACT_APP_API_ENDPOINT ?? "NO_REACT_APP_API_ENDPOINT";

  // Create an Axios instance with default headers
  const axiosInstance = axios.create({
    headers: {
      // Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY ?? "NO_SECRET_KEY"}`
      Authorization: `Bearer ${config.REACT_APP_SECRET_KEY ?? "NO_SECRET_KEY"}`,
    },
  });

  export const checkStatus = async () => {
    try {
      const response = await axiosInstance.get(`${API_ENDPOINT}/status`);
      console.log("checkStatus(): " + response.data);
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  export type DealerLogo = {
    imageMetadata: {
      id: number;
      filename: string;
      metadata: {
        size: number;
        width: number;
        height: number;
        mimetype: string;
        image_type: string;
      };
      mimetype: string;
      size: number;
      type: string;
      url: string;
    };
  };
  export const fetchDealerLogos = async () => {
    try {
      const response = await axiosInstance.get<DealerLogo[]>(
        `${API_ENDPOINT}/image/dealerLogos`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };

  export const uploadProfileImage = async function (
    image: Blob,
    userId: string
  ): Promise<ImageFile | undefined> {
    if (!image) {
      return;
    }

    const formData = new FormData();
    formData.append("file", image);
    formData.append("userId", userId);
    formData.append("type", "avatar");

    try {
      const response = await axiosInstance.post(
        `/api/file/upload/single`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data as ImageFile;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  export const uploadFile = async function (
    file: File,
    image_type: string
  ): Promise<ImageFile | undefined> {
    console.debug("ImageApiService.uploadFile();");
    console.debug(" ... {file,image_type}: ", { file, image_type });

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("image_type", image_type);

    try {
      const response = await axiosInstance.post(
        `/api/file/upload/single`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data as ImageFile;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };
  export const uploadResizeFile = async function (
    file: File,
    image_type: string
  ): Promise<ImageFile | undefined> {
    console.debug("ImageApiService.uploadResizeFile();");
    console.debug(" ... {file,image_type}: ", { file, image_type });

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("image_type", image_type);
    formData.append("size", "500");

    try {
      const response = await axiosInstance.post(
        `/api/image/upload/resize`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data as ImageFile;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };
  export const resizeFile = async function (
    fileName: string,
    image_type: string
  ): Promise<ImageFile | undefined> {
    console.log("ImageApiService.resizeFile();", { fileName, image_type });
    console.log(" ... {file,image_type}: ", { fileName, image_type });

    if (!fileName) {
      return;
    }

    console.log(" fileName: ", fileName);

    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("image_type", image_type);

    try {
      const response = await axiosInstance.post(`/api/image/resize`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };

  export const stitchAd = async function (
    file: File
  ): Promise<ImageFile | undefined> {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    console.log(" ... formData: ", formData);

    try {
      const response = await axiosInstance.post(
        `/api/image/stitchOneImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data as ImageFile;
    } catch (error: any) {
      console.error(error);
      throw new Error(error.message);
    }
  };
}
