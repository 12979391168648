import { useState } from "react";
import { ImageApiService } from "../services/ImageApiService.service";


export const GetDealerLogosButton:React.FC<{}> = ({}) => {
    const [dealerLogos, setDealerLogos] = useState([] as ImageApiService.DealerLogo[]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const getDealerLogos = async () => {
        setLoading(true);
        try {
            const data = await ImageApiService.fetchDealerLogos();
            console.debug(' ... dealerLogos', data);
            setDealerLogos(data);
        } catch (error) {
            setError(typeof error !== "string" ? JSON.stringify(error) : error);
        }
        setLoading(false);
    };

    return (
        <div>
            <button 
                onClick={getDealerLogos}
                className="px-4 py-4 font-medium bg-blue-50 border border-blue-500 hover:bg-blue-100 transition rounded-md"
            >
                Get Dealer Logos
            </button>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {dealerLogos.length > 0 && (
                <div>
                    <p>Length: {dealerLogos.length}</p>
                    <ul>
                        {dealerLogos.map(({imageMetadata}) => (
                            <li key={'file' + imageMetadata.id + imageMetadata.filename} style={{border:"solid black 1px"}}>
                                <p>filename: {imageMetadata.filename}</p>
                                <p>metadata: {JSON.stringify(imageMetadata.metadata, null, 2)}</p>
                                <img src={imageMetadata.url} alt={imageMetadata.filename} />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};