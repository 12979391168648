import { useRef, useState } from "react";
import { ImageFile } from "../models";
import { ImageApiService } from "../services/ImageApiService.service";

export const UploadBlock: React.FC<{
  label: string;
  image_type: string | null;
}> = ({ label, image_type }) => {
  type UploadBlockState = {
    files: FileList | null;
    errorMessage: string;
    isLoading: boolean;
    response: ImageFile | undefined;
  };
  const [state, setState] = useState<UploadBlockState>({
    files: null,
    errorMessage: "",
    isLoading: false,
    response: undefined,
  });
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [imageType, setImageType] = useState<string | null>(image_type);
  const imageTypeRef = useRef<HTMLSelectElement>(null);
  const imageTypeOtherTextRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, errorMessage: "" });
    setSelectedFile(event.target.files?.[0]);
  };

  const handleImageTypeSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.debug(" ... e.target.value", e.target.value);
    setImageType(e.target.value);
  };

  const uploadFile = async (
    file: File | undefined,
    image_type: string = "none"
  ) => {
    console.log("uploadFile()", { file, image_type });
    if (state.isLoading) return;
    if (file?.type.includes("image") === false) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: "Please select an image file to upload",
      });
      return;
    }

    if (file == null) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: "Please select a file before uploading",
      });
      return;
    }

    setState({ ...state, isLoading: true, errorMessage: "" });
    try {
      if (image_type === "other") {
        image_type = imageTypeOtherTextRef.current?.value ?? "other";
      }
      const imageResponse = await ImageApiService.uploadFile(file, image_type);
      setState({ ...state, response: imageResponse });
    } catch (e) {
      let errorMessage: string =
        "Error uploading the image" + (typeof e === "string")
          ? (e as string)
          : JSON.stringify(e);
      console.error("UploadBlock.uploadFile() ", errorMessage);
      setState({ ...state, errorMessage });
    }

    state.isLoading = false;
  };
  const uploadResizeFile = async (
    file: File | undefined,
    image_type: string = "none"
  ) => {
    console.log("uploadResizeFile()", { file, image_type });
    if (state.isLoading) return;
    if (file?.type.includes("image") === false) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: "Please select an image file to upload",
      });
      return;
    }

    if (file == null) {
      setState({
        ...state,
        isLoading: false,
        errorMessage: "Please select a file before uploading",
      });
      return;
    }

    setState({ ...state, isLoading: true, errorMessage: "" });
    try {
      if (image_type === "other") {
        image_type = imageTypeOtherTextRef.current?.value ?? "other";
      }
      const imageResponse = await ImageApiService.uploadResizeFile(
        file,
        image_type
      );
      setState({ ...state, response: imageResponse });
    } catch (e) {
      let errorMessage: string =
        "Error uploading the image" + (typeof e === "string")
          ? (e as string)
          : JSON.stringify(e);
      console.error("UploadBlock.uploadFile() ", errorMessage);
      setState({ ...state, errorMessage });
    }

    state.isLoading = false;
  };
  // const resizeFile = async (
  //   file: File | undefined,
  //   image_type: string = "none"
  // ) => {
  //   console.log("resizeFile()", {
  //     file,
  //     image_type,
  //   });
  //   if (state.isLoading) {
  //     console.log("... already loading");
  //     return;
  //   }
  //   if (file?.type.includes("image") === false) {
  //     setState({
  //       ...state,
  //       isLoading: false,
  //       errorMessage: "Please select an image file to upload",
  //     });
  //     return;
  //   }

  //   if (file == null) {
  //     setState({
  //       ...state,
  //       isLoading: false,
  //       errorMessage: "Please select a file before uploading",
  //     });
  //     return;
  //   }

  //   setState({ ...state, isLoading: true, errorMessage: "" });
  //   try {
  //     if (image_type === "other") {
  //       image_type = imageTypeOtherTextRef.current?.value ?? "other";
  //     }
  //     console.log("... calling ImageApiService.resizeFile()", {
  //       file,
  //       image_type,
  //     });

  //     if (state.response?.filename == null) {
  //       throw new Error("No file name to resize");
  //     }

  //     const imageResponse = await ImageApiService.resizeFile(
  //       state.response?.filename,
  //       image_type
  //     );
  //     setState({ ...state, response: imageResponse });
  //   } catch (e) {
  //     let errorMessage: string =
  //       "Error uploading the image" + (typeof e === "string")
  //         ? (e as string)
  //         : JSON.stringify(e);
  //     console.error("UploadBlock.resizeFile() ", errorMessage);
  //     setState({ ...state, errorMessage });
  //   }

  //   state.isLoading = false;
  // };
  console.log(state.response?.filename);

  return (
    <div id="upload_block" className="flex flex-col justify-center mt-5">
      <div>
        {state.errorMessage != null && state.errorMessage.length > 0 ? (
          <div className="px-5 py-5 my-5 w-full max-w-2xl text-left bg-orange-500 border border-orange-300">
            <h3 className="text-2xl">Error</h3>
            <p>{state.errorMessage}</p>
          </div>
        ) : null}

        <div className="border border-slate-400 my-5 p-8 input-group">
          <h1>{label}</h1>
          <input
            name="fileInput"
            type="file"
            onChange={handleFileInputChange}
          />

          {image_type == null ? (
            <div>
              <label htmlFor="image_type">Image Type</label>
              <select
                ref={imageTypeRef}
                name="image_type"
                id="image_type"
                onChange={handleImageTypeSelectChange}
              >
                <option value="none">None</option>
                <option value="logo">Logo</option>
                <option value="banner">Banner</option>
                <option value="other">Other</option>
              </select>
              {imageType === "other" ? (
                <input
                  type="text"
                  name="other"
                  placeholder="Please specify"
                  ref={imageTypeOtherTextRef}
                />
              ) : null}
            </div>
          ) : null}

          {state.isLoading ? (
            <div className="px-4 py-4 font-medium bg-green-500 border border-green-500 rounded-md">
              Loading...
            </div>
          ) : (
            <div>
              <button
                className="px-4 py-4 font-medium bg-blue-500 border border-blue-500 hover:bg-blue-300 transition rounded-md"
                onClick={() => uploadFile(selectedFile, imageType ?? "none")}
              >
                Upload!
              </button>
              <button
                className="px-4 py-4 font-medium bg-blue-500 border border-blue-500 hover:bg-blue-300 transition rounded-md"
                onClick={() =>
                  uploadResizeFile(selectedFile, imageType ?? "none")
                }
              >
                Resize!
              </button>
            </div>
          )}
        </div>
      </div>

      {state.response != null ? (
        <div className="w-full max-w-2xl overflow-hidden" v-if="response">
          <figure className="border border-slate-500">
            <img
              className="mx-auto"
              alt="uploaded file preview"
              src={state.response.url}
            />
            <a
              href={state.response.url}
              target="_blank"
              className="underline my-5 text-xl"
              rel="noreferrer"
            >
              Link here
            </a>
          </figure>
          <div className="p-5 border border-slate-500 text-left my-5 w-full overflow-hidden">
            <pre className="whitespace-pre-wrap">
              {JSON.stringify(state.response, null, 2)}
            </pre>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UploadBlock;
